import * as firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyBz56oB7iQyhGPitrzAycjFvcOql0E1-hI",
    authDomain: "movement-tracker-457bc.firebaseapp.com",
    databaseURL: "https://movement-tracker-457bc.firebaseio.com",
    projectId: "movement-tracker-457bc",
    storageBucket: "movement-tracker-457bc.appspot.com",
    messagingSenderId: "305745823704",
    appId: "1:305745823704:web:b4bb74c79efaaf376714ad",
    measurementId: "G-N641MYFBWS"
};
firebase.initializeApp(firebaseConfig);

export default firebase;